import React, { Component } from 'react'
import styled from 'styled-components'

const IframeDiv = styled.div`
  iframe {
    display: block; /* iframes are inline by default */
    background: #fff;
    border: none; /* Reset default border */
    height: 100vh; /* Viewport-relative units */
    width: 100vw;
  }
`

export default class Iframe extends Component {
  iframe() {
    return {
      __html: `<iframe src=${this.props.pageContext.url}></iframe>`,
    }
  }

  render() {
    return (
      <div>
        <IframeDiv dangerouslySetInnerHTML={this.iframe()} />
      </div>
    )
  }
}
